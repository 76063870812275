<!-- 打卡记录 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <el-date-picker v-model="select.month" type="month" placeholder="选择月" format="yyyy-MM" value-format="yyyy-MM" :clearable="false" @change="curr=1;getList()">
            </el-date-picker>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="name" label="业务员" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="count" label="累计打卡次数" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="handleShowDetail(scope.row)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {
                month: ''
            },
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        var myDate = new Date();
        var currentMonth1 = myDate.getMonth();
        var firstDay2 = new Date(myDate.getFullYear(), currentMonth1, 1)
        this.select['month'] = this.dataFormat(firstDay2);

        this.getList();
    },
    methods: {
        dataFormat(time) {
            return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}`;
        },
        getList: function() {
            this.http.post('/admin.Admin/indexDay', {
                curr: this.curr,
                row: this.row,
                month: this.select.month
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            })
        },
        handleShowDetail: function(row) {
            this.$router.push({
                path: '/ClockinRecordsDetail',
                query: {
                    id: row.id,
                    name: row.name,
                    month: this.select.month
                }
            })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
